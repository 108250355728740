var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyPaymentMethodForm",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "payment_method-form",
          title: "Add payment method",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.company,
              scope: _vm.COMPANY_SCOPES.paymentMethodForm,
              element: "payment method",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "ui-validate",
            {
              staticClass: "d-flex flex-column",
              on: { status: _vm.isValidForm },
            },
            [
              _c("p", { staticClass: "emobg-body-1 mb-4" }, [
                _vm._v(
                  " The new payment method added to the company will be the active payment method. "
                ),
              ]),
              _c("ui-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.select",
                    value: {
                      isRequired: true,
                      isNotPlaceHolderValidation:
                        _vm.isNotPlaceHolderValidation,
                    },
                    expression:
                      "{\n          isRequired: true,\n          isNotPlaceHolderValidation,\n        }",
                    modifiers: { select: true },
                  },
                ],
                staticClass: "w-100 order-1",
                attrs: {
                  "value-prop": "inputs.type",
                  label: "Payment method*",
                  name: "type",
                },
                domProps: { options: _vm.paymentMethodOptions },
                on: { selectoption: _vm.onSelectPaymentType },
              }),
              _vm.inputs.type ===
              _vm.get(_vm.COMPANY_PAYMENT_METHODS_OPTIONS, "sepa.value")
                ? [
                    _c("ui-text-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.input",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n            isRequired: true,\n          }",
                          modifiers: { input: true },
                        },
                      ],
                      staticClass: "my-4 order-2",
                      attrs: {
                        value: _vm.inputs.holder,
                        label: "Holder*",
                        placeholder: "Enter the holder name",
                        name: "holder",
                      },
                      on: { changevalue: _vm.onChangeHolder },
                    }),
                    _c("ui-text-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.input",
                          value: {
                            isRequired: true,
                            checkIban: _vm.checkIban,
                          },
                          expression:
                            "{\n            isRequired: true,\n            checkIban,\n          }",
                          modifiers: { input: true },
                        },
                      ],
                      staticClass: "order-3",
                      attrs: {
                        value: _vm.inputs.number,
                        label: "IBAN*",
                        placeholder: "Enter the IBAN",
                        name: "iban",
                      },
                      on: { changevalue: _vm.onChangeNumber },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }